
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SrvService {
  myordersfefe:Observable<any[]>;
  uid:any;
  uname:any;
  co:any;
  res:Observable<any[]>;

 // baseUrl:string = "https://100.0.0.207/test11/";
  baseUrl:string = "https://dutchvalley-eg.com/test11/";

constructor(private  httpClient : HttpClient) {
  this.uname= localStorage.getItem("usname")||"";
 }

login2(username:string, password:string){
let parm={ "usid":username, "uspassword":password}
return this.httpClient.post(this.baseUrl+"loginxx.php",parm).subscribe();
}

login1(val1:any,val2:any)
{
  return this.httpClient.get<any>(this.baseUrl+"loginxx.php?usname="+val1 +"&uspass=" + val2);
  
}

login(val1:any,val2:any):Observable<any[]>
{
  
  this.res=this.httpClient.get<any>(this.baseUrl+"loginn.php?usid="+val1 +"&uspassword=" + val2);
   //this.uid=this.res['usid'];
   //this.uname=this.res['usname'];
 //  localStorage.setItem("usname",this.uname);
  return this.res;
  
}
loginch(val1:any,val2:any):Observable<any[]>
{
  
  return this.res=this.httpClient.get<any>(this.baseUrl+"loginnch.php?usid="+val1 +"&uspassword=" + val2);
    
  
}

chngpass(val1:any,val2:any,val3:any):Observable<any[]>
{
  this.res=this.httpClient.get<any>(this.baseUrl+"chngpassword.php?usid="+val1 +"&npassword=" + val2 + "&usname=" + val3);
  return this.res;
  
}


getcompany(val1:any):Observable<any[]>
{
  return this.httpClient.get<any>(this.baseUrl+"getcompany.php?usid="+val1 );
 
  
}
getpath(val1:any,val2:any):Observable<any[]>
{
  return this.httpClient.get<any>(this.baseUrl+"getpath.php?comcode="+val1+"&usid=" + val2);
 
  
}
getactions(val1:any):Observable<any[]>
{
  return this.httpClient.get<any>(this.baseUrl+"getactions.php?pathcode="+val1);
 
  
}
getcount(val1:any):Observable<any[]>
{
  return this.httpClient.get<any>(this.baseUrl+"getcount.php?usid="+val1 );
 
  
}

getmyorders(val1:any,val2:any,val3:any):Observable<any[]>
{
  //alert(this.baseUrl+"getmyorders.php?usid="+val1+"&srchtxt="+val2 + "&strt=" + val3);
  return this.httpClient.get<any>(this.baseUrl+"getmyorders.php?usid="+val1+"&srchtxt="+val2 + "&strt=" + val3);
 
  
}
getmyorders5(val1:any):Observable<any[]>
{
  return this.httpClient.get<any>(this.baseUrl+"getmyorders5.php?usid="+val1 );
 
  
}
getmyorders55(val1:any):Observable<any[]>
{

 this. myordersfefe=this.httpClient.get<any>(this.baseUrl+"getmyorders5.php?usid="+val1 );
  return  this.myordersfefe ;
 
  
}

getorders(val1:any,val2:any,val3:any,val4:any,val5:any):Observable<any[]>
{

 return this.httpClient.get<any>(this.baseUrl+"getorders.php?usid="+val1+"&comcode="+val2+"&pathcode="+val3+"&actionid="+val4+"&srchtxt="+val5 );
 
  
}
getorders1(val1:any,val2:any,val3:any,val4:any,val5:any,val6:any):Observable<any[]>
{
  return this.httpClient.get<any>(this.baseUrl+"getordersxy.php?usid="+val1+"&comcode="+val2+"&pathcode="+val3+"&actionid="+val4+"&srchtxt="+val5 +"&strt="+val6 );
 
  
}
getactionoptions(val1:any):Observable<any[]>
{
  return this.httpClient.get<any>(this.baseUrl+"getactionoptions.php?actionid="+val1 );
 
  
}
//http://localhost/test1/login.php?usid=مدير&uspassword=Asd@x
getodetails(val1:any):Observable<any[]>
{
  return this.httpClient.get<any>(this.baseUrl+"getodtls.php?orderid="+val1 );
 
  
}

getpic(val1:any):Observable<any[]>
{
  return this.httpClient.get<any>(this.baseUrl+"getpic.php?usid="+val1 );
}

insertonlineaction(oid:any,cid:any,aid:any,uiid:any,opid:any,cmm:any)
{
 // alert(uiid);
  return this.httpClient.get<any>(this.baseUrl+"insertonlineaction.php?oid="+oid +"&cid=" + cid +"&aid=" + aid +"&usid="+  uiid +"&opid=" + opid +"&cmm=" + cmm);
  
}

}



 
