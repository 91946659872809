import { Component } from '@angular/core';
import { SrvService } from './srv.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  /*countt:any;
  usname:any;
  pic:any;
  usr:any;*/
  constructor(private sr:SrvService) {}
 /* ngOnInit() {
    this.usr= localStorage.getItem("usr")||"";
    
    this.pic= localStorage.getItem("pic")||"";
    this.usname= localStorage.getItem("usname")||"";
    this.usname= this.sr.uname ||"";
  }
*/
}
