import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
 
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dboard',
    loadChildren: () => import('./dboard/dboard.module').then( m => m.DboardPageModule)
  },
  {
    path: 'main',
    loadChildren: () => import('./main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'flox',
    loadChildren: () => import('./flox/flox.module').then( m => m.FloxPageModule)
  },
  {
    path: 'allorders',
    loadChildren: () => import('./allorders/allorders.module').then( m => m.AllordersPageModule)
  },
  {
    path: 'neword',
    loadChildren: () => import('./neword/neword.module').then( m => m.NewordPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'myord',
    loadChildren: () => import('./myord/myord.module').then( m => m.MyordPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
